import TYPES from '@/types';

// application
import Translator from '@/modules/shared/domain/i18n/translator';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class WealthPolicyInProcessOfCancellationBannerViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly i18n_namespace = 'components.banners.wealth_policy_in_process_of_cancellation';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);
}
