















import { Component, Vue } from 'vue-property-decorator';
import WealthPolicyInProcessOfCancellationBannerViewModel
  from '@/vue-app/view-models/components/banners/wealth-policy-in-process-of-cancellation-banner-view-model';

@Component({
  name: 'WealthPolicyInProcessOfCancellationBanner',
})
export default class WealthPolicyInProcessOfCancellationBanner extends Vue {
  banner_view_model = Vue.observable(new WealthPolicyInProcessOfCancellationBannerViewModel());
}
